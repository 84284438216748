<template>
  <div class="container">
    <headeBar
      title="寒假练体育 考试有底气"
      left-arrow
      @click-left="newAppBack"
      style="z-index: 9999; position: relative"
    />
    <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/camp202201/1.jpg" />
    <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/camp202201/2.gif" @click="receiveGift" />
    <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/camp202201/3.jpg" />
    <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/camp202201/4.jpg" />

    <confirm-dialog :showConfirm="showConfirm" :contentType="2" text="恭喜，领取成功!" :btnTextArr="['关闭', '去约课']" @firstBtn="closeConfirm" @secondBtn="goto"></confirm-dialog>
  </div>
</template>

<script>
import { newAppBack, appType } from '@/lib/appMethod'
import headeBar from '@/components/app/headBar'
import confirmDialog from '@/components/confirm/confirm'
import userMixin from '@/mixin/userMixin'
import wx from 'weixin-js-sdk'

export default {
  components: {
    headeBar,
    confirmDialog
  },
  mixins: [userMixin],
  data() {
    return {
      canReceive: false,
      showConfirm: false
    };
  },
  async created() {
    this.appTypeStr = appType()
    await this.$getAllInfo(); //获取userId,token
    this.getActivityInfo()
  },
  methods: {
    newAppBack,
    getActivityInfo() {
      this.$axios.post(this.baseURLApp + '/receive/activity/getActivityInfo', {
        userId: this.userId,
        token: this.token,
        hasWx: true
      }).then((res) => {
        if (res.code == 1) {
          this.canReceive = res.data.receiveStatus === 'CAN_RECEIVE' ? true : false
        }
      })
    },
    receiveGift() {
      if (!this.canReceive) {
        this.$toast('不能重复领取哦~');
        return
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios.post(this.baseURLApp + '/receive/activity/receiveVoucher', {
        userId: this.userId,
        token: this.token,
        hasWx: true
      }).then((res) => {
        if (res.code == 1) {
          this.getActivityInfo()
          this.showConfirm = true
          this.$toast.clear();
        }
      })
    },
    closeConfirm() {
      this.showConfirm = false
    },
    goto() {
      if (this.appTypeStr === "ios") {
        window.webkit.messageHandlers.pushCourseTab.postMessage({
          type: '2'
        });
      } else if (this.appTypeStr === "and") {
        App.web2native(1003, "");
      } else {
        wx.miniProgram.navigateTo({
          url: `/pages/campList/index`
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.container {
  img {
    display: block;
    width: 100%;
    margin: -1px 0;
  }
}
</style>